import React, {useState, useEffect, useRef} from 'react'
import {AutoTextBox} from '../components'
import { ModuleApi } from '../api';

type ModuleCommentProps = {
   readonly moduleId: number;
   readonly multiComment:boolean;
   readonly readOnly:boolean;
   readonly phraseKey:string
   readonly subId?:number;
   readonly sectionId?:number;
   readonly displayFrame?:boolean;
   readonly header?:string;
   readonly noMargin?:boolean;
 };

const keyPressTimer = 1000;
let inputTimer = null;

function ModuleComment(props:ModuleCommentProps) {

   const [comments, setComments] = useState([]);
   const [staticCommentHeader, setStaticCommentHeader] = useState("");

   useEffect(() => {
      //Fetch comments for this module
      ModuleApi.getComments(props.moduleId, props.subId??0, props.sectionId??0)
      .then(
         (response) => { 
            setComments(checkIfCommentShouldBeAdded(response.data));
         },
         (error) => {
            console.log(error);
         })
      if(props.header != null && props.header != '' && props.multiComment == false) {
         setStaticCommentHeader("Kommentar til " + props.header.toLowerCase());
      }
   }, [props]);


   const checkIfCommentShouldBeAdded = (array) => {
      if (props.multiComment == false && array.length > 0) {
         // never add. Default commend should always have been added.
         return array;
      }

      // if (props.multiComment == false && array.length == 0) {
      //    console.log(props.phraseKey + " Missing Definition in backend");
      // }


      var add = true;
      if (array.length > 0) {
         var last = array[array.length-1];
         if ((last.heading == null || last.heading == '') && (last.comment == null || last.comment == '')) {
            add = false;
         }
      }
      if (add)
         return addBlank(array);
      else 
         return array;
   }

   const addBlank = (array) => {
      var blank = {
         moduleCommentId:0,
         moduleId:props.moduleId,
         subId:props.subId??0,
         sectionId:props.sectionId??0,
         heading: staticCommentHeader != null && staticCommentHeader != '' ? staticCommentHeader : '',
         comment:'',
         order:array.length,
         deleted:false
      }
      array.push(blank);

      return array;
   }

   const onCommentsChange = (id, value) => {
      if (props.multiComment == false && staticCommentHeader != null && staticCommentHeader != '') {
         setComments(
            comments.map(item => 
                item.moduleCommentId === id 
                ? {...item, comment : value, heading: staticCommentHeader} 
                : item 
        ))
      }
      else{
         setComments(
            comments.map(item => 
                item.moduleCommentId === id 
                ? {...item, comment : value} 
                : item 
        ))
      }
     var copy = comments.find(c=>c.moduleCommentId ==id);
     if (copy == null) {
      return;
     }

     copy.comment = value;
     if (props.multiComment == false && staticCommentHeader != null && staticCommentHeader != '') {
      copy.heading = staticCommentHeader;
      }
     saveComment(copy);
   }

   
   const onTextChange = (id, e) => {
      const { name, value } = e.target;
      setComments(
         comments.map(item => 
             item.moduleCommentId === id 
             ? {...item, heading : value} 
             : item 
      ))

      var copy = comments.find(c=>c.moduleCommentId ==id);
      if (copy == null) {
         return;
      }
      copy.heading = value;

      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }
      inputTimer = setTimeout(() => {
         saveComment(copy);
      }, keyPressTimer);  
   }

   
   const onTextBlur = (id, e) => {
      const { name, value } = e.target;
      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }


      setComments(
         comments.map(item => 
             item.moduleCommentId === id 
             ? {...item, heading : value} 
             : item 
      ))
      
      var copy = comments.find(c=>c.moduleCommentId ==id);
      if (copy == null) {
         return;
      }

      copy.heading = value;
      saveComment(copy);
   }

   const saveComment = (comment) => {
      ModuleApi.saveComments(comment)
      .then(
         (response) => { 
            if (comment.moduleCommentId == 0){
               var updated = comments.map(item => 
                  item.moduleCommentId === comment.moduleCommentId 
                  ? {...item, moduleCommentId : response.data.moduleCommentId} 
                  : item );
               setComments(checkIfCommentShouldBeAdded(updated))
            }
         },
         (error) => {
            console.log(error);
         })
   }

   var commentsHtml = null;
   if (comments) {
      commentsHtml = comments.map((comment, index) => {

         let inputHtml = (
            <div className="description-input">
               <input className="description-input" key={"comment_" + index } placeholder="Valgfri overskrift" type="text" value={comment.heading} 
                  onChange={(e) => onTextChange(comment.moduleCommentId, e)} 
                  onBlur={(e) => onTextBlur(comment.moduleCommentId, e)}/>
            </div>
         );

         return ( 
            <div className={`${(props.displayFrame == null || props.displayFrame) ? 'module-section' : ''} ${props.noMargin ? 'module-section-nomargin' : ''}`}>
               {props.multiComment ? 
                  <div className={(props.displayFrame == null || props.displayFrame)?'category-header-module':''}>{inputHtml}</div>
               :
                  <div className={(props.displayFrame == null || props.displayFrame)?'category-header-module':''}>{staticCommentHeader}</div>
               }
               <div className={(props.displayFrame == null || props.displayFrame)?'content-frame':'content-frame-blank'}>
                  <AutoTextBox defaultRows={2}  text={comment.comment} onValueChangeCb={(value) =>onCommentsChange(comment.moduleCommentId, value)} phraseRegisterKey={props.phraseKey} readOnly={props.readOnly}></AutoTextBox>
               </div>
            </div>  
         )          
      });
   }

  
   return <>      
     {commentsHtml}
   </>
}
  
export default ModuleComment;